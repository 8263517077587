<template>
    <div class="page flex-col">
        <div class="box_1 flex-row">
            <div class="section_1 flex-col">

                <header>
                    <van-nav-bar
                            title="个人信息"
                            left-text=""
                            left-arrow
                            @click-left="onClickLeft"
                    />
                </header>
                <div class="group_3 flex-col"></div>
                <div class="text-wrapper_2 flex-row justify-between">
                    <div class="text_6">姓名
                        <input type="text" class="text_7" style="text-indent: 3em; border: none;"
                               v-model="userMember.name"
                               placeholder="请输入姓名"/>
                    </div>
                </div>
                <div class="group_4 flex-col"></div>
                <div class="text-wrapper_3 flex-row justify-between">
                    <div class="text_8">手机号码
                        <input type="text" class="text_9" style="text-indent: 1em; border: none;"
                               v-model="userMember.account" disabled placeholder="请输入手机号"/>
                    </div>
                </div>
                <div class="group_5 flex-col"></div>
                <div class="text-wrapper_4 flex-row justify-between">
                    <div class="text_10">登录密码
                        <input type="password" class="text_11" style="text-indent: 1em; border: none;"
                               v-model="userMember.pwd"
                               placeholder="请输入登录密码"/>
                    </div>
                </div>
                <div class="group_6 flex-col"></div>
                <div class="text-wrapper_5 flex-row justify-between">
                    <div class="text_12">联系人
                        <input type="text" class="text_13" style="text-indent: 2em; border: none;"
                               v-model="userMember.contactUser" placeholder="请输入联系人"/>
                    </div>
                </div>
                <div class="group_6 flex-col"></div>
                <div class="text-wrapper_5 flex-row justify-between">
                    <div class="text_12">联系电话
                        <input type="text" class="text_13" style="text-indent: 1em; border: none;"
                               v-model="userMember.contactPhone" placeholder="请输入联系电话"/>
                    </div>
                </div>
                <div class="group_6 flex-col"></div>
                <div class="text-wrapper_5 flex-row justify-between">
                    <div class="text_12">详细地址
                        <input type="text" class="text_13" style="text-indent: 1em; border: none;"
                               v-model="userMember.address" placeholder="请输入详细地址"/>
                    </div>
                </div>
            </div>
            <div class="section_2 flex-col">
                <div class="text-wrapper_6 flex-col" @click="saveMemberInfo"><span class="text_15">保存</span></div>
            </div>

        </div>
    </div>
</template>
<script>
    import {queryUserByAccount,saveMemberInfo} from "@/api/user";

    export default {
        data() {
            return {
                userMember: {},
            };
        },
        mounted() {
            const user = localStorage.getItem('userInfoDs')
            if(user){
                queryUserByAccount(JSON.parse(user).account).then(response => {
                    if (response.code == 200) {
                        this.userMember = response.data
                    }
                });
            }else{
                this.$router.push('/login')
            }

        },
        methods: {
            saveMemberInfo(){
                saveMemberInfo(this.userMember).then(response => {
                    if (response.code == 200) {
                        this.$model({
                            show: true,
                            title: "提示",
                            content: response.msg,
                            confirmButton: false,
                            cancelButton: true
                        });
                        this.$router.push('/wode')
                    }
                });
            },
            onClickLeft() {
                this.$router.push('/wode')
            }
        }
    };
</script>
<style scoped lang="css" src="./assets/index.rem.css"/>